import { render, staticRenderFns } from "./kplus-account-modal.vue?vue&type=template&id=cc2f4f0c&scoped=true&"
import script from "./kplus-account-modal.vue?vue&type=script&lang=js&"
export * from "./kplus-account-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc2f4f0c",
  null
  
)

export default component.exports